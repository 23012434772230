import { useState, useEffect } from "react";
import { Row, Col, Form, Input, Button } from "antd";

const defaultFormValues = {
  name: '',
}

function EquipmentTypeForm({ onSubmit, initialValues = defaultFormValues, onDelete, isLoading }) {
  const [values, setValues] = useState(initialValues);

  useEffect(() => {
    setValues(initialValues);
  }, [initialValues]);

  const handleChange = (field, value) => {
    setValues((old) => ({ ...old, [field]: value }));
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(values);
  }

  return (
    <div style={{ padding: 24, background: '#f0f2f5'}}>
      <Form layout="vertical" initialValues={values}>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item label="Name" name="name" >
              <Input value={values.name} onChange={(e) => handleChange('name', e.target.value)} />
            </Form.Item>
          </Col>
        </Row>
        
        <Row style={{ justifyContent: 'flex-end', marginTop: 24}}>
          <Col>
            <Button type="primary" onClick={handleSubmit} style={{ marginRight: 24}} loading={isLoading} >Save</Button>
            <Button type="danger" disabled={onDelete ? false : true} onClick={() => onDelete(values.id)}>Delete</Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
}

export default EquipmentTypeForm