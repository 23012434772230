import { useNavigate } from "react-router-dom";

import { notification } from "antd";

import useCreateEquipment from "../../hooks/equipments/useCreateEquipment";
import EquipmentForm from "../../components/equipmentForm";


function NewEquipment() {
  const navigate = useNavigate();

  const {
    mutate: createEquipment, 
    isLoading: isLoadingCreate, 
    isSuccess: isSuccessCreate, 
    isError: isErrorCreate, 
    reset: resetCreateEquipment
  } = useCreateEquipment();

  const onSubmit = async (values) => {
    createEquipment(values);
  }

  function showAlert(type, message) {
    notification[type]({ message: message });
    resetCreateEquipment();

    if(type === 'success'){
      navigate('/equipments/');
    }
  }

  return (
    <>
      {isSuccessCreate && showAlert('success', 'Equipment update successfully')}
      {isErrorCreate && showAlert('error', 'Error on update Equipment')}
      <EquipmentForm onSubmit={onSubmit} isLoading={isLoadingCreate} />
    </>
  );
}

export default NewEquipment