import { useMutation } from 'react-query'

import { queryClient } from '../..'
import api from '../../services/api'

export default function useSaveCompany() {
  return useMutation(
    (values) =>
      api.put(`/company/${values.id}`, values).then((res) => res.data),
    {
      onMutate: (values) => {
        queryClient.cancelQueries('companies')

        const oldCompanies = queryClient.getQueryData(['companies', values.id])

        queryClient.setQueryData(['companies', values.id], values)

        return () => queryClient.setQueryData(['companies', values.id], oldCompanies)
      },
      onError: (error, values, rollback) => rollback(),
      onSuccess: (data, variables) => {
        queryClient.invalidateQueries('companies')
        queryClient.invalidateQueries(['companies', variables.id])
      },
    }
  )
}
