import { useState, useEffect } from "react";
import { Row, Col, Form, Input, Select, Divider, Button } from "antd";

import useAccounts from '../hooks/accounts/useAccounts';

const { Option } = Select;

const groupReport = {
  Equipamento: {
    labelName: 'Equipamento',
    labelsReport: {
      modEquip: 'Modelo do Equipamento',
      serialNum: 'Número Serial do Equipamento',
      tipoManut: 'Tipo da Manutenção',
      dataManut: ['Data da Próxima Manutenção', 'Data da Última Manutenção'],
    },
  },
  Obra: {
    labelName: 'Obra',
    labelsReport: {
      nomeObra: 'Nome da Obra',
      operatorId: 'ID do Operador',
    },
  },
  acessorio: {
    labelName: 'Acessório',
    labelsReport: {
      assFabri: 'Fabricante do Acessório',  
      assTipo: 'Tipo do Acessório',
      assDim: 'Dimensão Acessório',
      assBarcode: 'Código de Barras do Acessório',
      assModoConf: 'Modo Configuração Acessório',
    },
  },
  solda: {
    labelName: 'Dados da Solda',
    labelsReport: {    
      numSolda: 'Número da Solda',
      dataHoraSolda: 'Data e Hora da Solda',
      tempoSoldaNom_s: 'Tempo de Solda Nominal (seg)',
      tempoSoldaReal_s: 'Tempo de Solda Real (seg)',
      voltNom: 'Voltagem Nominal',
      voltAplic: 'Voltagem Aplicada',
      resistNom: 'Resistência Nominal',
      resistMed: 'Resistência Medida',
      energia_kJ: 'Energia (kJ)',
      tensaoAC: 'Tensão AC',
      tempAmb: 'Temperatura Ambiente',
      statusErro: 'Status de Erro',
    },
  },
  versao: {
    labelName: 'Informações do dispositivo',
    labelsReport: {    
      efusId: 'ID eFusion',
      efusHwVer: 'Versão do Hardware',
      efusFwVer: 'Versão do Firmware',
      batVolt_10mV: 'Bateria do dispositivo (V)'
    },
  },
}

const tipoManutOptions = {
  P: 'Próxima',
  U: 'Última'
}
let dataManutOptions;

function ReportForm({ onSubmit, initialValues, isLoading }) {
  const [values, setValues] = useState(initialValues);

  useEffect(() => {
    setValues(initialValues);
  }, [initialValues]);

  const handleChange = (field, value) => {
    setValues((old) => ({ ...old, [field]: value }));
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit({id: values.id, os_id: values.os_id});
  }

  return (
    <div style={{ padding: 24, background: '#f0f2f5'}}>
      <Form layout="vertical" initialValues={values}>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item label="Ordem de Serviço" name="os_id">
              <Input value={values.os_id} onChange={(e) => handleChange('os_id', e.target.value)}/>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Account" name="account">
              <Input value={values.account.name} disabled style={{color: 'black'}} />
            </Form.Item>
          </Col>
          {Object.entries(groupReport).map(([key, value], index) =>
            <>
              <Divider plain>{value.labelName}</Divider>
              {Object.entries(value.labelsReport).map(([key, value], index) => {
                if(key === 'tipoManut'){
                  dataManutOptions = values.report_data[key] === 'P' ? 0 : 1;
                  return ( 
                    <Col span={6} key={index}>
                      <Form.Item label={value}>
                        <Input value={tipoManutOptions[values.report_data[key]]} disabled style={{color: 'black'}}/>
                      </Form.Item>
                    </Col>
                  )
                }else if(key === 'dataManut'){
                  return ( 
                    <Col span={6} key={index}>
                      <Form.Item label={value[dataManutOptions]}>
                        <Input value={values.report_data[key]} disabled style={{color: 'black'}}/>
                      </Form.Item>
                    </Col>
                  )
                }
                else{
                  return ( 
                  <Col span={6} key={index}>
                    <Form.Item label={value}>
                      <Input value={values.report_data[key]} disabled style={{color: 'black'}}/>
                    </Form.Item>
                  </Col>)
                }
              })}
            </>
          )}
        </Row>
        <Row style={{ justifyContent: 'flex-end', marginTop: 24}}>
          <Col>
            <Button type="primary" onClick={handleSubmit} style={{ marginRight: 24}} loading={isLoading} >Save</Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
}

export default ReportForm;