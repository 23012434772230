import { useAuth } from "../hooks/auth/useAuth";
import AppRoutes from "./appRoutes";
import SignInRoutes from "./signinRoutes";

function Routes() {
  const { signed } = useAuth();

  return signed ? <AppRoutes /> : <SignInRoutes />;
}

export default Routes;
