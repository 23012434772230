import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Layout, Menu } from 'antd';
import { BuildOutlined, UserOutlined, DatabaseOutlined, BankOutlined, ClusterOutlined, FileTextOutlined } from '@ant-design/icons';

const { Sider } = Layout;

function SideMenu(){
  const [collapsed, setCollapsed] = useState(false);

  return (
    <Sider 
      width={200}
      className="site-layout-background"
      theme="light"
      collapsible 
      collapsed={collapsed} 
      onCollapse={() => setCollapsed(!collapsed)}
    >
      <Menu  defaultSelectedKeys={['1']} mode="inline">
        <Menu.Item key="1" icon={<BuildOutlined />}>
          <Link to="/">Status</Link>
        </Menu.Item>
        <Menu.Item key="2" icon={<UserOutlined />}>
          <Link to="/accounts">Accounts</Link>
        </Menu.Item>
        <Menu.Item key="3" icon={<BankOutlined />}>
          <Link to="/companies">Companies</Link>
        </Menu.Item>
        <Menu.Item key="4" icon={<DatabaseOutlined />}>
          <Link to="/equipments">Equipments</Link>
        </Menu.Item>
        <Menu.Item key="5" icon={<ClusterOutlined />}>
          <Link to="/equipment-types">Equipment Types</Link>
        </Menu.Item>
        <Menu.Item key="6" icon={<FileTextOutlined />}>
          <Link to="/reports">Reports</Link>
        </Menu.Item>
      </Menu>
    </Sider>
  );
}

export default SideMenu;