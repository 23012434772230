import { useState, useEffect } from "react";
import { Row, Col, Form, Input, Radio, Switch, Button, Select } from "antd";

import useAccounts from '../hooks/accounts/useAccounts';
import useEquipmentTypes from '../hooks/equipmentTypes/useEquipmentTypes'

const { Option } = Select;

const defaultFormValues = {
  name: '',
  email: '',
  phone: '',
  position: '',
  company: null,
  type: 'user',
  active: 0
}

function EquipmentForm({ onSubmit, initialValues = defaultFormValues, onDelete, isLoading }) {
  const accountsQuery = useAccounts();
  const equipmentTypesQuery = useEquipmentTypes();

  const [values, setValues] = useState(initialValues);

  const accountsSelectOptions = accountsQuery.isLoading ? [] : 
  accountsQuery.data.map((account) => <Option key={account.id} value={account.id}>{account.name}</Option> );

  const equipmentTypesSelectOptions = equipmentTypesQuery.isLoading ? [] : 
  equipmentTypesQuery.data.map((equipmentType) => <Option key={equipmentType.id} value={equipmentType.id}>{equipmentType.name}</Option> );

  useEffect(() => {
    setValues(initialValues);
  }, [initialValues]);

  const handleChange = (field, value) => {
    setValues((old) => ({ ...old, [field]: value }));
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(values);
  }

  return (
    <div style={{ padding: 24, background: '#f0f2f5'}}>
      <Form layout="vertical" initialValues={values}>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item label="Name" name="name" >
              <Input value={values.name} onChange={(e) => handleChange('name', e.target.value)} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Serial number" name="serialNumber">
              <Input value={values.serialNumber} onChange={(e) => handleChange('serialNumber', e.target.value)} />
            </Form.Item>
          </Col>
        </Row>
        
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item label="Equipment type" name="equipmentType">
              <Select onChange={(value) => handleChange('equipmentType', value)} >
                {equipmentTypesSelectOptions} 
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Account" name="account">
              <Select onChange={(value) => handleChange('account', value)} >
                {accountsSelectOptions} 
              </Select>
            </Form.Item>
          </Col>
        </Row>
      
        <Row style={{ justifyContent: 'flex-end', marginTop: 24}}>
          <Col>
            <Button type="primary" onClick={handleSubmit} style={{ marginRight: 24}} loading={isLoading} >Save</Button>
            <Button type="danger" disabled={onDelete ? false : true} onClick={() => onDelete(values.id)}>Delete</Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
}

export default EquipmentForm