import { useParams, useNavigate } from "react-router-dom";

import { notification } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import useEquipment from "../../hooks/equipments/useEquipment";
import useSaveEquipment from "../../hooks/equipments/useSaveEquipment";
import useDeleteEquipment from "../../hooks/equipments/useDeleteEquipment";
import EquipmentForm from "../../components/equipmentForm";


function Equipment() {
  const {id} = useParams();
  const navigate = useNavigate();
  const equipmentQuery = useEquipment(id);

  const equipmentData = equipmentQuery.isLoading ? {} : {...equipmentQuery.data, equipmentType: equipmentQuery.data.equipmentType.id, account: equipmentQuery.data.account.id }

  const {mutate: saveEquipment, isLoading: isLoadingSave, isSuccess: isSuccessSave, isError: isErrorSave,  reset: resetSaveEquipment} = useSaveEquipment();
  const {mutate: deleteEquipment} = useDeleteEquipment();

  // console.log('mutatuin ->',test);

  const onSubmit = async (values) => {
    saveEquipment(values);
  }

  const onDelete = async (id) => {
    await deleteEquipment(id);
    navigate('/equipments');
  }

  function showAlert(type, message) {
    notification[type]({ message: message });
    resetSaveEquipment();
  }

  return (
    <>
    {isSuccessSave && showAlert('success', 'Equipment update successfully')}
    {isErrorSave && showAlert('error', 'Error on update Equipment')}
    {equipmentQuery.isLoading ? (<LoadingOutlined />) : <EquipmentForm onSubmit={onSubmit} initialValues={equipmentData} onDelete={onDelete} isLoading={isLoadingSave} isSuccess={isSuccessSave} />}
    </>
  );
}

export default Equipment