import { useMutation } from 'react-query'

import { queryClient } from '../..'
import api from '../../services/api'

export default function useDeleteEquipmentType() {
  return useMutation(
    (equipmentTypeId) => api.delete(`/equipment-type/${equipmentTypeId}`).then((res) => res.data),
    {
      onError: (error, variables, rollback) => {
        rollback && rollback()
      },
      onSuccess: (data, equipmentTypeId) => {
        const previousEquipmentTypes = queryClient.getQueryData('equipmentTypes');

        const optimisticEquipmentTypes = previousEquipmentTypes.filter((d) => d.id !== equipmentTypeId);

        queryClient.setQueryData('equipmentTypes', optimisticEquipmentTypes);
        queryClient.invalidateQueries('equipmentTypes');
      },
    }
  )
}