import { useMutation } from 'react-query'

import { queryClient } from '../..'
import api from '../../services/api'

export default function useCreateCompany() {
  return useMutation(
    (values) => api.post('/company', values).then((res) => res.data),
    {
      onSuccess: () => queryClient.invalidateQueries('companies'),
    }
  )
}