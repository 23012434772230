import { useMutation } from 'react-query'

import { queryClient } from '../..'
import api from '../../services/api'

export default function useCreateAccount() {
  return useMutation(
    (values) => api.post('/account', values).then((res) => res.data),
    {
      onSuccess: () => queryClient.invalidateQueries('accounts'),
    }
  )
}