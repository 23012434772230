import { useMutation } from 'react-query'

import { queryClient } from '../..'
import api from '../../services/api'

export default function useDeleteCompany() {
  return useMutation(
    (companyId) => api.delete(`/company/${companyId}`).then((res) => res.data),
    {
      onError: (error, variables, rollback) => {
        rollback && rollback()
      },
      onSuccess: (data, companyId) => {
        const previousCompanies = queryClient.getQueryData('companies');

        const optimisticCompanies = previousCompanies.filter((d) => d.id !== companyId);

        queryClient.setQueryData('companies', optimisticCompanies);
        queryClient.invalidateQueries('companies');
      },
    }
  )
}