import { useMutation } from 'react-query'

import { queryClient } from '../..'
import api from '../../services/api'

export default function useSaveAccount() {
  return useMutation(
    (values) =>
      api.put(`/account/${values.id}`, values).then((res) => res.data),
    {
      onMutate: (values) => {
        queryClient.cancelQueries('accounts')

        const oldAccounts = queryClient.getQueryData(['accounts', values.id])

        queryClient.setQueryData(['accounts', values.id], values)

        return () => queryClient.setQueryData(['accounts', values.id], oldAccounts)
      },
      onError: (error, values, rollback) => rollback(),
      onSuccess: (data, variables) => {
        queryClient.invalidateQueries('accounts')
        queryClient.invalidateQueries(['accounts', variables.id])
      },
    }
  )
}
