import { useNavigate } from "react-router-dom";

import { notification } from "antd";

import useCreateEquipmentType from "../../hooks/equipmentTypes/useCreateEquipmentType";
import EquipmentTypeForm from "../../components/equipmentTypeForm";


function NewEquipmentType() {
  const navigate = useNavigate();

  const {
    mutate: createEquipmentType, 
    isLoading: isLoadingCreate, 
    isSuccess: isSuccessCreate, 
    isError: isErrorCreate, 
    reset: resetCreateEquipmentType
  } = useCreateEquipmentType();

  const onSubmit = async (values) => {
    createEquipmentType(values);
  }

  function showAlert(type, message) {
    notification[type]({ message: message });
    resetCreateEquipmentType();

    if(type === 'success'){
      navigate('/equipment-types/');
    }
  }

  return (
    <>
      {isSuccessCreate && showAlert('success', 'Equipment Type update successfully')}
      {isErrorCreate && showAlert('error', 'Error on update Equipment Type')}
      <EquipmentTypeForm onSubmit={onSubmit} isLoading={isLoadingCreate} />
    </>
  );
}

export default NewEquipmentType