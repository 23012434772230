import { Outlet, Link } from "react-router-dom";

import { Layout, Row, Col, Tooltip } from "antd";
import { LogoutOutlined } from "@ant-design/icons";

import SideMenu from "../../components/sideMenu";
import { useAuth } from "../../hooks/auth/useAuth";

const { Header, Content } = Layout;

function Home() {
  const context = useAuth();

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Header
        style={{
          backgroundColor: "#1E8A9A",
          paddingLeft: 25,
          paddingRight: 25,
        }}
      >
        <Row style={{ paddingBotton: 20 }}>
          <Col span={12}>
            <Link to="/">
              <img
                src={process.env.PUBLIC_URL + "/favicon.ico"}
                alt="Enablers logo"
                style={{ height: 55, objectFit: "scale-down" }}
              />
            </Link>
          </Col>
          <Col
            span={12}
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <Tooltip placement="left" title="Logout">
              <LogoutOutlined
                style={{ fontSize: 35, color: "#fff" }}
                onClick={context.Logout}
              />
            </Tooltip>
          </Col>
        </Row>
      </Header>
      <Layout>
        <SideMenu />
        <Layout style={{ padding: 24 }}>
          <Content
            style={{
              background: "#fff",
              padding: 24,
              margin: 0,
              minHeight: 750,
            }}
          >
            <Outlet />
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
}

export default Home;
