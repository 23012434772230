import { useParams, useNavigate } from "react-router-dom";

import { notification } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import useCompany from "../../hooks/companies/useCompany";
import useSaveCompany from "../../hooks/companies/useSaveCompany";
import useDeleteCompany from "../../hooks/companies/useDeleteCompany";
import CompanyForm from "../../components/companyForm";


function Company() {
  const {id} = useParams();
  const navigate = useNavigate();
  const companyQuery = useCompany(id);

  const companyData = companyQuery.isLoading ? {} : companyQuery.data

  const {mutate: saveCompany, isLoading: isLoadingSave, isSuccess: isSuccessSave, isError: isErrorSave,  reset: resetSaveCompany} = useSaveCompany();
  const {mutate: deleteCompany} = useDeleteCompany();

  // console.log('mutatuin ->',test);

  const onSubmit = async (values) => {
    saveCompany(values);
  }

  const onDelete = async (id) => {
    await deleteCompany(id);
    navigate('/companies');
  }

  function showAlert(type, message) {
    notification[type]({ message: message });
    resetSaveCompany();
  }

  return (
    <>
    {isSuccessSave && showAlert('success', 'Company update successfully')}
    {isErrorSave && showAlert('error', 'Error on update Account')}
    {companyQuery.isLoading ? (<LoadingOutlined />) : <CompanyForm onSubmit={onSubmit} initialValues={companyData} onDelete={onDelete} isLoading={isLoadingSave} isSuccess={isSuccessSave} />}
    </>
  );
}

export default Company