import { useNavigate } from "react-router-dom";
import { Input, Table, Button } from 'antd'

import useCompanies from '../../hooks/companies/useCompanies';

const { Search } = Input;

const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
  },
  {
    title: 'Name',
    dataIndex: 'name',

  },
];

function Companies() {
  const companiesQuery = useCompanies();
  const navigate = useNavigate();

  return (
    <>
      <h1>Companies</h1>
      <Button type="primary" onClick={() => navigate('/companies/new')} >New</Button>
      <div style={{ padding: 24, background: '#f0f2f5', marginTop: 12}}>
        <Search
          placeholder='Pesquisar'
        />
        <Table 
          style={{ marginTop: 24, minHeight: 500}} 
          columns={columns}
          rowKey={record => record.id}
          dataSource={companiesQuery.data}
          loading={companiesQuery.isLoading}
          onRow={(record) => {
            return {
              onClick: () => { navigate(`/companies/${record.id}`);},
            }}}
        />
      </div>
    </>
  );
}

export default Companies