import { useNavigate } from "react-router-dom";
import { Input, Table, Button } from 'antd'

import useReports from '../../hooks/reports/useReports';

const { Search } = Input;

const columns = [
  {
    title: 'ID eFusion',
    dataIndex: ['report_data', 'efusId'],
  },
  {
    title: 'Modelo da máquina',
    dataIndex: ['report_data', 'modEquip'],
  },
  {
    title: 'Número Serial da máquina',
    dataIndex: ['report_data', 'serialNum'],
  },
  {
    title: 'Número da Solda',
    dataIndex: ['report_data', 'numSolda'],
  },
  {
    title: 'Data da Solda',
    dataIndex: ['report_data', 'dataHoraSolda'],
  },
];

function Reports() {
  const reportsQuery = useReports();
  const navigate = useNavigate();

  let reportsData;

  if(!reportsQuery.isLoading){
    reportsData = reportsQuery.data.map((report => { return {...report, accountName: report.account.name }}));
  }

  return (
    <>
      <h1>Reports</h1>
      <div style={{ padding: 24, background: '#f0f2f5', marginTop: 12}}>
        <Search
          placeholder='Pesquisar'
        />
        <Table 
          style={{ marginTop: 24, minHeight: 500}} 
          columns={columns}
          rowKey={record => record.id}
          dataSource={reportsData}
          loading={reportsQuery.isLoading}
          onRow={(record) => {
            return {
              onClick: () => { navigate(`/reports/${record.id}`);},
            }}}
        />
      </div>
    </>
  );
}

export default Reports