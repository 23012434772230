import { createContext, useContext, useState, useEffect } from "react";
import api from "../../services/api";

const AuthContext = createContext({});

export function AuthProvider({ children }) {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const storagedUser = localStorage.getItem("@App:account");
    const storagedToken = localStorage.getItem("@App:token");

    if (storagedToken && storagedUser) {
      console.log(storagedUser);
      setUser(JSON.parse(storagedUser));
      api.defaults.headers.token = storagedToken;
    }
  }, []);

  async function Login(values) {
    const response = await api.post("/account/login", values);
    if(response.data) {
      setUser(response.data.account);
      api.defaults.headers.token = response.data.token;
      localStorage.setItem(
        "@App:account",
        JSON.stringify(response.data.account)
      );
      localStorage.setItem("@App:token", response.data.token);
    }else {
      throw new Error('Erro de login');
    }
  }

  function Logout() {
    setUser(null);

    localStorage.removeItem("@App:account");
    localStorage.removeItem("@App:token");
  }

  async function RequestPasswordReset(email) {
    await api.post("/account/request_password_reset", {
      email,
    });
  }

  async function ResetPassword(password, token) {
    await api.post(
      "/account/reset_password",
      {
        password,
      },
      { headers: { token: token } }
    );
  }

  return (
    <AuthContext.Provider
      value={{
        signed: Boolean(user),
        user,
        Login,
        Logout,
        RequestPasswordReset,
        ResetPassword,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  const context = useContext(AuthContext);

  return context;
}
