import { useMutation } from 'react-query'

import { queryClient } from '../..'
import api from '../../services/api';

export default function useDeleteEquipment() {
  return useMutation(
    (equipmentId) => api.delete(`/equipment/${equipmentId}`).then((res) => res.data),
    {
      onError: (error, variables, rollback) => {
        rollback && rollback()
      },
      onSuccess: (data, equipmentId) => {
        const previousEquipments = queryClient.getQueryData('equipments');

        const optimisticEquipment = previousEquipments.filter((d) => d.id !== equipmentId);

        queryClient.setQueryData('equipments', optimisticEquipment);
        queryClient.invalidateQueries('equipments');
      },
    }
  )
}