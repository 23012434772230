import { useMutation } from 'react-query'

import { queryClient } from '../..'
import api from '../../services/api'

export default function useCreateEquipment() {
  return useMutation(
    (values) => api.post('/equipment', values).then((res) => res.data),
    {
      onSuccess: () => queryClient.invalidateQueries('equipments'),
    }
  )
}