import { useMutation } from 'react-query'

import { queryClient } from '../..'
import api from '../../services/api'

export default function useSaveEquipment() {
  return useMutation(
    (values) =>
      api.put(`/equipment/${values.id}`, values).then((res) => res.data),
    {
      onMutate: (values) => {
        queryClient.cancelQueries('equipments')

        const oldEquipments = queryClient.getQueryData(['equipments', values.id])

        queryClient.setQueryData(['equipments', values.id], values)

        return () => queryClient.setQueryData(['equipments', values.id], oldEquipments)
      },
      onError: (error, values, rollback) => rollback(),
      onSuccess: (data, variables) => {
        queryClient.invalidateQueries('equipments')
        queryClient.invalidateQueries(['equipments', variables.id])
      },
    }
  )
}
