import { useParams, useNavigate } from "react-router-dom";

import { notification } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import useAccount from "../../hooks/accounts/useAccount";
import useSaveAccount from "../../hooks/accounts/useSaveAccount";
import useDeleteAccount from "../../hooks/accounts/useDeleteAccount";
import AccountForm from "../../components/accountForm";


function Account() {
  const {id} = useParams();
  const navigate = useNavigate();
  const accountQuery = useAccount(id);

  const accountData = accountQuery.isLoading ? {} : {...accountQuery.data, company: accountQuery.data.company.id}

  const {mutate: saveAccount, isLoading: isLoadingSave, isSuccess: isSuccessSave, isError: isErrorSave,  reset: resetSaveAccount} = useSaveAccount();
  const {mutate: deleteAccount} = useDeleteAccount();

  // console.log('mutatuin ->',test);

  const onSubmit = async (values) => {
    saveAccount(values);
  }

  const onDelete = async (id) => {
    await deleteAccount(id);
    navigate('/accounts');
  }

  function showAlert(type, message) {
    notification[type]({ message: message });
    resetSaveAccount();
  }

  return (
    <>
    {isSuccessSave && showAlert('success', 'Account update successfully')}
    {isErrorSave && showAlert('error', 'Error on update Account')}
    {accountQuery.isLoading ? (<LoadingOutlined />) : <AccountForm onSubmit={onSubmit} initialValues={accountData} onDelete={onDelete} isLoading={isLoadingSave} isSuccess={isSuccessSave} />}
    </>
  );
}

export default Account