import { useNavigate, useParams } from "react-router-dom";

import { LoadingOutlined } from "@ant-design/icons";

import useReport from "../../hooks/reports/useReport";
import useSaveReport from "../../hooks/reports/useSaveReport";
import ReportForm from "../../components/reportForm";
import { notification } from "antd";


function Report() {
  const {id} = useParams();
  const navigate = useNavigate();
  const reportQuery = useReport(id);

  const reportData = reportQuery.isLoading ? {} : {...reportQuery.data, account: reportQuery.data.account.name }

  const {mutate: saveReport, isLoading: isLoadingSave, isSuccess: isSuccessSave, isError: isErrorSave,  reset: resetSaveReport} = useSaveReport();

  const onSubmit = async (values) => {
    saveReport(values);
  }

  function showAlert(type, message) {
    notification[type]({ message: message });
    resetSaveReport();
  }

  return (
    <>
    {isSuccessSave && showAlert('success', 'Report update successfully')}
    {isErrorSave && showAlert('error', 'Error on update Report')}
    {reportQuery.isLoading ? (<LoadingOutlined />) : <ReportForm onSubmit={onSubmit} initialValues={reportData} isLoading={isLoadingSave} isSuccess={isSuccessSave} />}
    </>
  );
}

export default Report