import { useMutation } from 'react-query'

import { queryClient } from '../..'
import api from '../../services/api'

export default function useSaveEquipmentType() {
  return useMutation(
    (values) =>
      api.put(`/equipment-type/${values.id}`, values).then((res) => res.data),
    {
      onMutate: (values) => {
        queryClient.cancelQueries('equipmentTypes')

        const oldEquipmentTypes = queryClient.getQueryData(['equipmentTypes', values.id])

        queryClient.setQueryData(['equipmentTypes', values.id], values)

        return () => queryClient.setQueryData(['equipmentTypes', values.id], oldEquipmentTypes)
      },
      onError: (error, values, rollback) => rollback(),
      onSuccess: (data, variables) => {
        queryClient.invalidateQueries('equipmentTypes')
        queryClient.invalidateQueries(['equipmentTypes', variables.id])
      },
    }
  )
}
