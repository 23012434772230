import { Form, Input, Button, Row, Col, Modal, notification } from "antd";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { useAuth } from "../../hooks/auth/useAuth";
import { useState } from "react";

function Login() {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [recoverEmail, setRecoverEmail] = useState("");
  const [isRecoverLoading, setIsRecoverLoading] = useState(false);
  const context = useAuth();

  async function handleLogin(values) {
    await context.Login(values).catch((error) => {
      notification.error({ message: 'Erro ao fazer login', description: 'Por favor verifique seu usuário e senha.' })
    });
  }

  async function handleModalOk() {
    setIsRecoverLoading(true);
    await context.RequestPasswordReset(recoverEmail);
    setIsRecoverLoading(false);
    setIsModalVisible(false);
    notification.info({ message: 'Um link foi enviado ao seu endereço de email. Por favor verifique sua caixa de entrada.' });
  }

  return (
    <>
      <Modal
        title="Password Reset Form"
        visible={isModalVisible}
        confirmLoading={isRecoverLoading}
        onOk={handleModalOk}
        onCancel={() => setIsModalVisible(false)}
      >
        <Input
          prefix={<LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
          type="email"
          placeholder="Email"
          value={recoverEmail}
          onChange={(e) => setRecoverEmail(e.target.value)}
        />
      </Modal>
      <Row>
        <Col span={9}></Col>
        <Col span={6} style={{ textAlign: "center" }}>
          <img
            src="http://enablers.com.br/upload/logo_enablers2.png"
            style={{ width: 330, margin: 50 }}
            alt="Enablers"
          />
        </Col>
        <Col span={9}></Col>
      </Row>
      <Row>
        <Col span={9}></Col>
        <Col span={6}>
          <Form
            name="normal_login"
            className="login-form"
            initialValues={{
              remember: true,
            }}
            onFinish={handleLogin}
          >
            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  message: "Please input your Username!",
                },
              ]}
            >
              <Input
                prefix={<UserOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
                placeholder="Email"
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please input your Password!",
                },
              ]}
            >
              <Input
                prefix={<LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
                type="password"
                placeholder="Password"
              />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
                style={{ flex: 1, display: "inline-block" }}
              >
                Log in
              </Button>
              <Button
                type="default"
                className="login-form-button"
                style={{ marginLeft: 5, display: "inline-block" }}
                onClick={() => setIsModalVisible(true)}
              >
                Reset your password
              </Button>
            </Form.Item>
          </Form>
        </Col>
        <Col span={9}></Col>
      </Row>
    </>
  );
}

export default Login;
