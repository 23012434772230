import { BrowserRouter, Routes, Route } from "react-router-dom";

import Login from "../Pages/Login";
import ResetPassword from "../Pages/resetPassword";

function SignInRoutes() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login />}>
          <Route path="*" />
        </Route>
        <Route path="/password_reset/:token" element={<ResetPassword />} />
      </Routes>
    </BrowserRouter>
  );
}

export default SignInRoutes;
