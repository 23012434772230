import { useState, useEffect } from "react";
import { Row, Col, Form, Input, Radio, Switch, Button, Select } from "antd";

import useCompanies from "../hooks/companies/useCompanies";
import { useAuth } from "../hooks/auth/useAuth";

const { Option } = Select;

const defaultFormValues = {
  name: "",
  email: "",
  phone: "",
  position: "",
  company: null,
  type: "user",
  active: 0,
};

function AccountForm({
  onSubmit,
  initialValues = defaultFormValues,
  onDelete,
  isLoading,
}) {
  const companiesQuery = useCompanies();
  const [values, setValues] = useState(initialValues);
  const [isRecoverLoading, setIsRecoverLoading] = useState(false);
  const context = useAuth();

  const selectOptions = companiesQuery.isLoading
    ? []
    : companiesQuery.data.map((company) => (
        <Option key={company.id} value={company.id}>
          {company.name}
        </Option>
      ));

  useEffect(() => {
    setValues(initialValues);
  }, [initialValues]);

  const handleChange = (field, value) => {
    setValues((old) => ({ ...old, [field]: value }));
  };

  const handleSwitchChange = (field, checked) => {
    const value = checked ? 1 : 0;
    setValues((old) => ({ ...old, [field]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(values);
  };

  async function handlerequestReset() {
    setIsRecoverLoading(true);
    await context.RequestPasswordReset(values.email);
    setIsRecoverLoading(false);
  }

  return (
    <div style={{ padding: 24, background: "#f0f2f5" }}>
      <Form layout="vertical" initialValues={values}>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item label="Name" name="name">
              <Input
                value={values.name}
                onChange={(e) => handleChange("name", e.target.value)}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Email" name="email">
              <Input
                value={values.email}
                onChange={(e) => handleChange("email", e.target.value)}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col span={12}>
            <Form.Item label="Phone" name="phone">
              <Input
                value={values.phone}
                onChange={(e) => handleChange("phone", e.target.value)}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Position" name="position">
              <Input
                value={values.position}
                onChange={(e) => handleChange("position", e.target.value)}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col span={12}>
            <Form.Item label="Company" name="company">
              <Select onChange={(value) => handleChange("company", value)}>
                {selectOptions}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12} style={{ display: "flex", alignItems: "center" }}>
            <Row gutter={24}>
              <Col>
                <Button type="primary">Access this Account</Button>
              </Col>
              <Col>
                <Button
                  type="primary"
                  onClick={handlerequestReset}
                  loading={isRecoverLoading}
                >
                  Reset Password
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Row>
              <Form.Item label="Type" style={{ marginRight: 36 }}>
                <Radio.Group
                  value={values.type}
                  onChange={(e) => handleChange("type", e.target.value)}
                >
                  <Radio value={"admin"}>Admin</Radio>
                  <Radio value={"user"}>User</Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item label="Active?">
                <Switch
                  checked={Boolean(values.active)}
                  onChange={(checked) => {
                    handleSwitchChange("active", checked);
                  }}
                />
              </Form.Item>
            </Row>
          </Col>
        </Row>

        <Row style={{ justifyContent: "flex-end", marginTop: 24 }}>
          <Col>
            <Button
              type="primary"
              onClick={handleSubmit}
              style={{ marginRight: 24 }}
              loading={isLoading}
            >
              Save
            </Button>
            <Button
              type="danger"
              disabled={onDelete ? false : true}
              onClick={() => onDelete(values.id)}
            >
              Delete
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
}

export default AccountForm;
