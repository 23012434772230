import { useNavigate } from "react-router-dom";
import { Input, Table, Button } from 'antd'

import useEquipmentTypes from '../../hooks/equipmentTypes/useEquipmentTypes';

const { Search } = Input;

const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
  },
  {
    title: 'Name',
    dataIndex: 'name',

  },
];

function EquipmentTypes() {
  const equipmentTypesQuery = useEquipmentTypes();
  const navigate = useNavigate();

  return (
    <>
      <h1>Equipment Types</h1>
      <Button type="primary" onClick={() => navigate('/equipment-types/new')} >New</Button>
      <div style={{ padding: 24, background: '#f0f2f5', marginTop: 12}}>
        <Search
          placeholder='Pesquisar'
        />
        <Table 
          style={{ marginTop: 24, minHeight: 500}} 
          columns={columns}
          rowKey={record => record.id}
          dataSource={equipmentTypesQuery.data}
          loading={equipmentTypesQuery.isLoading}
          onRow={(record) => {
            return {
              onClick: () => { navigate(`/equipment-types/${record.id}`);},
            }}}
        />
      </div>
    </>
  );
}

export default EquipmentTypes