import { useNavigate } from "react-router-dom";

import { notification } from "antd";

import useCreateAccount from "../../hooks/accounts/useCreateAccount";
import AccountForm from "../../components/accountForm";


function NewAccount() {
  const navigate = useNavigate();

  const {
    mutate: createAccount, 
    isLoading: isLoadingCreate, 
    isSuccess: isSuccessCreate, 
    isError: isErrorCreate, 
    reset: resetCreateAccount
  } = useCreateAccount();

  const onSubmit = async (values) => {
    createAccount(values);
  }

  function showAlert(type, message) {
    notification[type]({ message: message });
    resetCreateAccount();

    if(type === 'success'){
      navigate('/accounts/');
    }
  }

  return (
    <>
      {isSuccessCreate && showAlert('success', 'Account update successfully')}
      {isErrorCreate && showAlert('error', 'Error on update Account')}
      <AccountForm onSubmit={onSubmit} isLoading={isLoadingCreate} />
    </>
  );
}

export default NewAccount