import { BrowserRouter, Routes, Route } from "react-router-dom";

import Home from "../Pages/Home";
import Status from "../Pages/status/status";
import Accounts from "../Pages/accounts/accounts";
import NewAccount from "../Pages/accounts/newAccount";
import Account from "../Pages/accounts/account";
import Companies from "../Pages/companies/companies";
import NewCompany from "../Pages/companies/newCompany";
import Company from "../Pages/companies/company";
import Equipments from "../Pages/equipments/equipments";
import NewEquipment from "../Pages/equipments/newEquipment";
import Equipment from "../Pages/equipments/equipment";
import EquipmentTypes from "../Pages/equipmentTypes/equipmentTypes";
import NewEquipmentType from "../Pages/equipmentTypes/newEquipmentType";
import EquipmentType from "../Pages/equipmentTypes/equipmentType";
import Reports from "../Pages/reports/reports";
import Report from "../Pages/reports/report"

function AppRoutes() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />}>
          <Route index element={<Status />} />
          <Route path="accounts" element={<Accounts />} />
          <Route path="accounts/new" element={<NewAccount />} />
          <Route path="accounts/:id" element={<Account />} />
          <Route path="companies" element={<Companies />} />
          <Route path="companies/new" element={<NewCompany />} />
          <Route path="companies/:id" element={<Company />} />
          <Route path="equipments" element={<Equipments />} />
          <Route path="equipments/new" element={<NewEquipment />} />
          <Route path="equipments/:id" element={<Equipment />} />
          <Route path="equipment-types" element={<EquipmentTypes />} />
          <Route path="equipment-types/new" element={<NewEquipmentType />} />
          <Route path="equipment-types/:id" element={<EquipmentType />} />
          <Route path="reports" element={<Reports />} />
          <Route path="reports/:id" element={<Report />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default AppRoutes;
