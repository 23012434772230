import { useNavigate } from "react-router-dom";

import { notification } from "antd";

import useCreateCompany from "../../hooks/companies/useCreateCompany";
import CompanyForm from "../../components/companyForm";


function NewCompany() {
  const navigate = useNavigate();

  const {
    mutate: createCompany, 
    isLoading: isLoadingCreate, 
    isSuccess: isSuccessCreate, 
    isError: isErrorCreate, 
    reset: resetCreateCompany
  } = useCreateCompany();

  const onSubmit = async (values) => {
    createCompany(values);
  }

  function showAlert(type, message) {
    notification[type]({ message: message });
    resetCreateCompany();

    if(type === 'success'){
      navigate('/companies/');
    }
  }

  return (
    <>
      {isSuccessCreate && showAlert('success', 'Company update successfully')}
      {isErrorCreate && showAlert('error', 'Error on update Company')}
      <CompanyForm onSubmit={onSubmit} isLoading={isLoadingCreate} />
    </>
  );
}

export default NewCompany