import { useParams, useNavigate } from "react-router-dom";
import { Form, Input, Button, Row, Col, Modal } from "antd";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { useAuth } from "../../hooks/auth/useAuth";

function ResetPassword() {
  const { token } = useParams();
  const navigate = useNavigate();
  const context = useAuth();

  async function handleReset(values) {
    if (values.password === values.confirmPassword) {
      await context.ResetPassword(values.password, token);
      navigate("/");
    }
  }

  return (
    <>
      <Row>
        <Col span={9}></Col>
        <Col span={6} style={{ textAlign: "center" }}>
          <img
            src="http://enablers.com.br/upload/logo_enablers2.png"
            style={{ width: 330, margin: 50 }}
            alt="Enablers"
          />
        </Col>
        <Col span={9}></Col>
      </Row>
      <Row>
        <Col span={9}></Col>
        <Col span={6}>
          <Form
            name="reset"
            initialValues={{
              remember: true,
            }}
            onFinish={handleReset}
          >
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please input your new Password!",
                },
              ]}
            >
              <Input
                prefix={<LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
                type="password"
                placeholder="Please input your new Password!"
              />
            </Form.Item>
            <Form.Item
              name="confirmPassword"
              rules={[
                {
                  required: true,
                  message: "Please confirm your new Password!",
                },
              ]}
            >
              <Input
                prefix={<LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
                type="password"
                placeholder="Please confirm your new Password!!"
              />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
                style={{ flex: 1, display: "inline-block" }}
              >
                Save New Password
              </Button>
              <Button
                type="default"
                className="login-form-button"
                style={{ marginLeft: 5, display: "inline-block" }}
                onClick={() => navigate("/")}
              >
                Back
              </Button>
            </Form.Item>
          </Form>
        </Col>
        <Col span={9}></Col>
      </Row>
    </>
  );
}

export default ResetPassword;
