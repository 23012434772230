import { useMutation } from 'react-query'

import { queryClient } from '../..'
import api from '../../services/api'

export default function useDeleteAccount() {
  return useMutation(
    (accountId) => api.delete(`/account/${accountId}`).then((res) => res.data),
    {
      onError: (error, variables, rollback) => {
        rollback && rollback()
      },
      onSuccess: (data, accountId) => {
        const previousAccounts = queryClient.getQueryData('accounts');

        const optimisticAccounts = previousAccounts.filter((d) => d.id !== accountId);

        queryClient.setQueryData('accounts', optimisticAccounts);
        queryClient.invalidateQueries('accounts');
      },
    }
  )
}