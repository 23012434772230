import { useParams, useNavigate } from "react-router-dom";

import { notification } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import useEquipmentType from "../../hooks/equipmentTypes/useEquipmentType";
import useSaveEquipmentType from "../../hooks/equipmentTypes/useSaveEquipmentType";
import useDeleteEquipmentType from "../../hooks/equipmentTypes/useDeleteEquipmentType";
import EquipmentTypeForm from "../../components/equipmentTypeForm";


function EquipmentType() {
  const {id} = useParams();
  const navigate = useNavigate();
  const equipmentTypeQuery = useEquipmentType(id);

  const equipmentTypeData = equipmentTypeQuery.isLoading ? {} : equipmentTypeQuery.data

  const {mutate: saveEquipmentType, isLoading: isLoadingSave, isSuccess: isSuccessSave, isError: isErrorSave,  reset: resetSaveEquipmentType} = useSaveEquipmentType();
  const {mutate: deleteEquipmentType} = useDeleteEquipmentType();

  // console.log('mutatuin ->',test);

  const onSubmit = async (values) => {
    saveEquipmentType(values);
  }

  const onDelete = async (id) => {
    await deleteEquipmentType(id);
    navigate('/equipment-types');
  }

  function showAlert(type, message) {
    notification[type]({ message: message });
    resetSaveEquipmentType();
  }

  return (
    <>
    {isSuccessSave && showAlert('success', 'Company update successfully')}
    {isErrorSave && showAlert('error', 'Error on update Account')}
    {equipmentTypeQuery.isLoading ? (<LoadingOutlined />) : <EquipmentTypeForm onSubmit={onSubmit} initialValues={equipmentTypeData} onDelete={onDelete} isLoading={isLoadingSave} isSuccess={isSuccessSave} />}
    </>
  );
}

export default EquipmentType